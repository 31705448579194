<template>
    <div>
        <titulo-pagamentos />

        <filtro-pagamentos
            @filtro="atualizarFiltro"
            @filtrar-por="getTitulos"
            @limpar-filtro="getTitulos"
        />

        <tabela-pagamentos
            :items="items"
            @gerar-recibo-lancamento="gerarReciboLancamento"
            @gerar-boleto="gerarBoleto"
        />

        <div
            v-if="filtro.length > 0 && pag.total == 0"
            class="text-center text-warning"
        >
            <span> {{ $t('FINANCEIRO.SEM_PAGAMENTOS') }} </span>
        </div>

        <paginacao v-model="pag" :total="pag.total" @input="getTitulos" />
    </div>
</template>
<script>
// Utils & Aux:
import breadcrumb from '@/common/utils/breadcrumb';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import datas from '@/common/utils/datas';
import pdf from '@/common/utils/pdf';

//Services
import TituloService from '@/common/services/titulo/titulo.service';
import FluxoCaixaService from '@/common/services/fluxo-caixa/fluxo-caixa.service';
import BoletoPixService from '@/common/services/boleto-pix/boleto-pix.service.js';

// Components:
import FiltroPagamentos from './components/FiltroPagamentos';
import TabelaPagamentos from './components/TabelaPagamentos';
import Paginacao from '@/components/paginacao/Paginacao';
import TituloPagamentos from './components/TituloPagamentos';

export default {
    components: {
        TituloPagamentos,
        FiltroPagamentos,
        TabelaPagamentos,
        Paginacao,
    },
    mounted() {
        breadcrumb.definir(this.$store, [
            { titulo: this.$t('FINANCEIRO.FINANCEIRO') },
            { titulo: this.$t('FINANCEIRO.PAGAMENTOS') },
        ]);
    },
    data() {
        return {
            items: [],
            pag: {
                numeroDaPagina: 1,
                tamanhoDaPagina: 10,
                total: 0,
            },
            filtro: {
                matriculaId: '',
            },
        };
    },
    methods: {
        getTitulos() {
            this.$store.dispatch(START_LOADING);
            TituloService.obterContasAReceberPorFiltroPaginado({
                ...this.pag,
                ...this.filtro,
            })
                .then(({ data }) => {
                    this.items = data.itens.map((item) => ({
                        ...item,
                        mes: datas.obterMesTextoAno(item.dataVencimento, true),
                    }));

                    this.pag.total = data.paginacao.totalDeElementos;
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },

        atualizarFiltro(filtro) {
            this.filtro = filtro;
        },

        gerarReciboLancamento(id) {
            this.$store.dispatch(START_LOADING);
            FluxoCaixaService.imprimirRecibo([id])
                .then(({ data }) => {
                    if (data.documento) {
                        //converte os bytes em array:
                        let bytes = pdf.base64ToArrayBuffer(data.documento);
                        // converte o array em blob
                        let blob = new Blob([bytes], {
                            type: 'application/pdf',
                        });

                        // faz download do PDF:
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = data.nomeArquivo;
                        link.click();
                    }
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },

        gerarBoleto(id) {
            this.$store.dispatch(START_LOADING);
            BoletoPixService.gerarBoletosDeTitulosIds(this.filtro.matriculaId, [
                id,
            ])
                .then(({ data }) => {
                    data.arquivo.forEach((a) => {
                        //converte os bytes em array:
                        let bytes = pdf.base64ToArrayBuffer(a.item2);
                        // converte o array em blob
                        let blob = new Blob([bytes], {
                            type: 'application/pdf',
                        });
                        // faz download do PDF:
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = a.item1;
                        link.click();
                    });
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },
    },
};
</script>
