import ApiService from '@/common/api/api.service';

let apiBasePath = 'LancamentoCaixa';
const FluxoCaixaService = {
    // FUNÇÕES DE BUSCA:
    async imprimirRecibo(ids) {
        let { data } = await ApiService.post(
            `${apiBasePath}/imprimir-recibo`,
            ids
        );
        return data;
    },
};

export default FluxoCaixaService;
