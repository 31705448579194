<template>
    <b-table
        :fields="fields"
        :items="items"
        :empty-text="$t('GERAL.NENHUM_REGISTRO')"
        head-variant="light"
        stacked="md"
        show-empty
        responsive
        striped
        hover
    >
        <template #cell(statusPagamentoDescricao)="item">
            <b-badge :variant="getStatusVariant(item.item.statusPagamentoId)">
                {{ item.item.statusPagamentoDescricao }}
            </b-badge>
        </template>
        <template #cell(acoes)="item">
            <b-button
                variant="info"
                @click="gerarBoleto(item.item.id)"
                v-if="
                    item.item.statusPagamentoId == 299 ||
                    item.item.statusPagamentoId == 142
                "
                >{{ $t('FINANCEIRO.GERAR_BOLETO') }}</b-button
            >
            <b-button
                variant="info"
                @click="gerarReciboLancamento(item.item.id)"
                v-if="item.item.statusPagamentoId == 300"
                >{{ $t('FINANCEIRO.GERAR_RECIBO') }}</b-button
            >
        </template>
    </b-table>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';
// import { START_LOADING, STOP_LOADING } from "@/store/Store";
// import mensagem from "@/common/utils/mensagem";

export default {
    props: {
        items: { type: Array, default: Array },
    },
    data() {
        return {
            fields: [
                {
                    key: 'mes',
                    label: this.$t('FINANCEIRO.MES'),
                    sortable: true,
                },
                {
                    key: 'dataVencimento',
                    label: this.$t('FINANCEIRO.VENCIMENTO'),
                    formatter: (value) => {
                        return value ? helpers.formatarDataBr(value) : '-';
                    },
                    sortable: true,
                },
                {
                    key: 'valor',
                    label: this.$t('FINANCEIRO.VALOR'),
                    formatter: (value) => {
                        return value ? helpers.formatarReal(value) : '-';
                    },
                    sortable: true,
                },
                {
                    key: 'statusPagamentoDescricao',
                    label: this.$t('FINANCEIRO.STATUS_PAGAMENTO'),
                    sortable: true,
                },
                {
                    key: 'acoes',
                    label: this.$t('GERAL.ACOES'),
                },
            ],
            statusVariants: {
                142: 'primary',
                299: 'warning',
                300: 'success',
                301: 'info',
                302: 'danger',
            },
        };
    },
    methods: {
        getStatusVariant(statusPagamentoId) {
            return this.statusVariants[statusPagamentoId] || 'secondary';
        },
        gerarReciboLancamento(id) {
            this.$emit('gerar-recibo-lancamento', id);
        },
        gerarBoleto(id) {
            this.$emit('gerar-boleto', id);
        },
    },
};
</script>
