<template>
    <div>
        <b-row>
            <b-col md="3">
                <input-select-search
                    ref="matriculaId"
                    :options="opcoes.alunosVinculados"
                    :label="$t('ACADEMICO.ALUNO')"
                    v-model="filtro.matriculaId"
                    required
                />
            </b-col>
            <b-col md="3">
                <input-date-only-btn
                    ref="periodoInicial"
                    v-model="filtro.dataLancamentoInicio"
                    :label="$t('FINANCEIRO.PERIODO_INICIAL')"
                />
            </b-col>
            <b-col md="3">
                <input-date-only-btn
                    ref="dataLancamentoAte"
                    v-model="filtro.dataLancamentoAte"
                    :label="$t('FINANCEIRO.PERIODO_FINAL')"
                />
            </b-col>
            <b-col md="auto">
                <b-button
                    class="botao-acao-filtro w-100 mb-3"
                    variant="primary"
                    @click="filtrar"
                >
                    {{ $t('GERAL.FILTRAR') }}
                </b-button>
            </b-col>
            <b-col md="auto">
                <b-button
                    class="botao-acao-filtro w-100 mb-3"
                    variant="secondary"
                    @click="limparFiltro"
                >
                    {{ $t('GERAL.LIMPAR') }}
                </b-button>
            </b-col>
        </b-row>
        <b-row> </b-row>
    </div>
    <!-- </template>
    </filtro> -->
</template>
<script>
// helps & aux
import JwtService from '@/common/utils/jwt';
import helpers from '@/common/utils/helpers';

//Services

// Components:
import { InputSelectSearch, InputDateOnlyBtn } from '@/components/inputs';

export default {
    components: {
        InputSelectSearch,
        InputDateOnlyBtn,
    },
    mounted() {
        this.$emit('refs', this.$refs);
        this.getAlunosVinculadosResponsavel();
    },
    data() {
        return {
            filtro: {
                matriculaId: '',
                dataLancamentoInicio: '',
                dataLancamentoAte: '',
            },
            opcoes: {
                alunosVinculados: [],
            },
        };
    },
    watch: {
        filtro: {
            handler(value) {
                this.$emit('filtro', value);
            },
            deep: true,
            immediate: true,
        },
        'filtro.matriculaId': {
            handler(value) {
                if (value) {
                    this.filtro = {
                        ...this.filtro,
                    };
                }
            },
            immediate: true,
        },
    },
    methods: {
        // FUNÇÕES DE OBTENÇÃO DE DADOS:
        getAlunosVinculadosResponsavel() {
            this.opcoes.alunosVinculados =
                JwtService.buscarAlunosVinculados().reduce(
                    (alunosSemDuplicatas, aluno) => {
                        const existe = alunosSemDuplicatas.some(
                            (a) => a.value === aluno.matriculaId
                        );
                        if (!existe) {
                            alunosSemDuplicatas.push({
                                text: aluno.nome,
                                value: aluno.matriculaId,
                            });
                        }
                        return alunosSemDuplicatas;
                    },
                    []
                );
        },

        validarFiltro() {
            return helpers.validarFormulario(this.$refs);
        },
        // FUNÇÕES DO FILTRO:
        filtrar() {
            if (!this.validarFiltro()) return;
            this.$emit('filtrar-por');
        },
        limparFiltro() {
            this.filtro = {
                matriculaId: '',
                dataLancamentoInicio: '',
                dataLancamentoAte: '',
            };
            this.$emit('limpar-filtro');
        },
    },
};
</script>
