import ApiService from "@/common/api/api.service";

const apiBasePath = "Titulo";

const TituloService = {
    async obterContasAReceberPorFiltroPaginado(filtro) {
        const { data } = await ApiService.get(
            `${apiBasePath}/contas-receber/obter-por-filtro`,
            {
                params: { ...filtro },
            }
        );
        return data;
    },
};

export default TituloService;
